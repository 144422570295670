import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import Link from "components/Link";
import Image from "components/Image";
import { handleHit } from "../../utils/handleHit";

const Card16Podcast = ({ className = "h-full", post, ratio = "aspect-w-3 xl:aspect-w-4 aspect-h-3", type = "" }) => {
    const { title, href, category, desc, featuredImage, viewdCount, commentCount, kod } = post;


    return (
        <Link href={href} onClick={() => handleHit(kod)} className={`nc-Card16Podcast relative flex flex-col ${className}`}>
            <div>
                <h3 className="text-xl ml-4 mb-2">{category}</h3>
            </div>
            <div
                className={`block flex-shrink-0 relative w-full rounded-3xl overflow-hidden ${ratio}`}
            >
                <Image
                    fill
                    alt=""
                    sizes="(max-width: 600px) 480px, 800px"
                    src={featuredImage}
                    className="object-cover"
                />
                <span className="bg-neutral-900 bg-opacity-30"></span>
            </div>

            {/* MAIN CONTENT */}
            <div className="w-11/12 transform -mt-24">
                {/* <div className="w-11/12 transform -mt-32">  */}
                <div
                    className={`p-5 bg-white dark:bg-neutral-900 ${type === "slider" ? "mt-16" : "mt-5 shadow-xl dark:shadow-2xl"} rounded-3xl rounded-tl-none flex flex-col flex-grow`}>
                    <h2 className={`nc-card-title block ${type === "slider" ? "text-md" : "sm:text-lg lg:text-xl"} ${type === "slider" && "h-6"} font-semibold text-neutral-900 dark:text-neutral-100`}>
                        <div className="line-clamp-3" title={title}>
                            {title}
                        </div>
                    </h2>

                    <span className={`block text-sm text-neutral-500 dark:text-neutral-400 my-1.5 ${type === "slider" && "h-6"}`}>
                        <span className="line-clamp-2">{desc}</span>
                    </span>

                    <div className="flex items-end justify-between mt-auto">
                        <PostCardLikeAndComment likeCount={viewdCount} commentCount={commentCount} href={href}
                            className="relative" />
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default Card16Podcast;
