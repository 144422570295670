import CardLarge1 from "components/CardLarge1/CardLarge1";
import Heading from "components/Heading/Heading";
import { PostDataType } from "data/types";
import React, { FC, useState, useEffect } from "react";

export interface SectionLargeSliderProps {
  className?: string;
  heading?: string;
  posts: PostDataType[];
  autoplayInterval?: number; // Optional prop for autoplay delay (ms)
}

const SectionLargeSlider: FC<SectionLargeSliderProps> = ({
  posts,
  heading = "",
  className = "",
  autoplayInterval = 5000, // Set default delay (can be overridden)
}) => {
  const [indexActive, setIndexActive] = useState(0);

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= posts.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  // Use useEffect for automatic rotation
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleClickNext();
    }, autoplayInterval);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [autoplayInterval, handleClickNext]); // Depend on autoplayInterval and handleClickNext


  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return posts.length - 1;
      }
      return state - 1;
    });
  };

  return (
    <div className={`nc-SectionLargeSlider relative ${className}`}>
      {!!heading && <Heading>{heading}</Heading>}
      {posts.map((item, index) => {
        if (indexActive !== index) return null;
        return (
          <CardLarge1
            key={item.id}
            onClickNext={handleClickNext}
            onClickPrev={handleClickPrev}
            post={item}
            totalCount={posts.length}
            activeIndex={indexActive + 1}
          />
        );
      })}
    </div>
  );
};

export default SectionLargeSlider;
