import React, { useEffect, useState } from "react";
import Logo from "components/Logo/Logo";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import { fetchAPI } from "../../hooks/fetchApı";
import LinkStripComponent from "../../pages/LinkStripComponent";

const Footer = () => {
    const [loading, setLoading] = useState(false);
    const [infoMenu, setInfoMenu] = useState([]);
    const [tags, setTags] = useState([]);
    const [metaAds, setMetaAds] = useState([]);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const menuData = await fetchAPI('getFooter', createFormData, sendRequest);

                const metaAdsData = await fetchAPI('getAds', createFormData, sendRequest, null, null, 47);
                setMetaAds(metaAdsData);

                menuData.info.forEach((item) => {
                    item.href = "/" + item.href;
                })

                menuData.tags = JSON.parse(menuData.tags)

                menuData.tags.forEach((item) => {
                    item.href = "/etiket/" + item.href;
                })

                setInfoMenu(menuData.info)

                setTags(menuData.tags);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }

        fetchData();
    }, [createFormData, sendRequest]);

    const widgetMenus = [
        {
            id: "5",
            title: "Bilgiler",
            menus: infoMenu,
        },
        {
            id: "1",
            title: "Modüller",
            menus: [
                {
                    label: 'Foto Galeri',
                    href: 'foto-galeri'
                },
                {
                    label: 'Video Galeri',
                    href: 'video-galeri'
                },
                {
                    label: "Avrupa'nın Sesi Gazete Arşivi",
                    href: 'yayinlar'
                },
            ],
        }
    ];

    const settings = useSelector((state) => state.settings);

    const renderWidgetMenuItem = (menu, index) => {
        return (
            <>
                {!loading && (
                    <div key={index} className="text-sm ">
                        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                            {menu.title}
                        </h2>
                        <ul className="mt-5 space-y-4">
                            {menu.menus.map((item, index) => (
                                <li key={index}>
                                    <Link
                                        key={index}
                                        className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white select-none"
                                        to={item.href}
                                    >
                                        {item.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {settings && (
                <div
                    className="nc-Footer relative py-16 lg:py-28 border-t border-neutral-200 dark:border-neutral-700 mt-12">
                    <div
                        className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 ">
                        <div
                            className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                            <div className="col-span-2 md:col-span-1">
                                <Logo />
                            </div>
                            <div className="col-span-2 flex items-center md:col-span-3">
                                <SocialsList1
                                    className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
                            </div>
                        </div>
                        {widgetMenus.map(renderWidgetMenuItem)}

                        <div className="text-sm">
                            <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                                Etkiketler
                            </h2>
                            <ul className="mt-5 space-y-4">
                                {tags.map((tag, key) => (
                                    <Link to={tag.href} key={key}
                                        className="text-neutral-400 dark:text-neutral-200 hover:text-black dark:hover:text-white select-none cursor-pointer">
                                        {tag.label}
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="text-center py-4 flex flex-col gap-y-2.5">
                        <div className="my-6">
                            <LinkStripComponent data={metaAds[0]?.link} />
                        </div>
                        <p>{settings.adres}</p>
                        <b>© AVRUPA'NIN SESİ 2014 - {new Date().getFullYear()} . Tüm hakları saklıdır.</b>
                    </div>
                </div>
            )
            }
        </>
    );
};

export default Footer;
